<template>
  <div class="tw-flex tw-h-screen tw-flex-col">
    <!-- Mobile layout -->
    <div class="tw-h-auto tw-bg-white sm:tw-hidden">
      <div class="tw-h-1/2 tw-justify-center tw-bg-gradient-to-b" :class="linearGradient">
        <!-- CaseWise logo (co-branded experience) -->
        <div
          v-if="cobrandedExperience"
          class="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-2xl tw-py-6 tw-pb-4"
        >
          <CaseWiseHeaderLogo />
        </div>
        <div
          v-else
          class="tw-flex tw-w-full tw-items-center tw-justify-center tw-rounded-2xl tw-pb-6"
        />

        <!-- White container -->
        <div class="tw-w-full tw-flex-1 tw-rounded-2xl tw-bg-white tw-pt-8">
          <img
            :src="cobrandedLogoUrl"
            :alt="branding.name"
            class="tw-mx-auto tw-h-auto tw-max-h-10 tw-max-w-[256px]"
          />
          <slot name="mobile-form" />
        </div>
      </div>
    </div>

    <!-- Desktop and tablet layout -->
    <div class="tw-hidden tw-h-full tw-w-full sm:tw-flex">
      <!-- Left side - Branding -->
      <div
        class="tw-flex tw-h-full tw-w-1/2 tw-flex-col tw-items-center tw-justify-center tw-bg-gradient-to-t"
        :class="[cobrandedExperience ? linearGradient : 'tw-bg-white']"
      >
        <Component
          :is="cobrandedExperience ? CaseWiseLoginLogo : 'img'"
          :src="!cobrandedExperience ? cobrandedLogoUrl : undefined"
          :alt="cobrandedExperience ? branding.name : 'CaseWise'"
          class="tw-h-auto tw-w-[256px] lg:tw-w-[430px] lg:tw-max-w-[430px]"
        />
      </div>

      <!-- Right side - Auth components -->
      <div
        class="tw-flex tw-h-full tw-w-1/2 tw-flex-col tw-items-center tw-justify-center tw-bg-gradient-to-t"
        :class="[cobrandedExperience ? 'tw-bg-white' : linearGradient, 'tw-py-6']"
      >
        <slot name="form" />
      </div>
    </div>

    <V2SiteFooter class="tw-relative tw-mt-auto tw-w-full" :theme="authFooter" />
    <AppToast />
  </div>
</template>

<script setup lang="ts">
import CaseWiseHeaderLogo from '~/assets/icons/CaseWiseHeaderLogo.svg'
import CaseWiseLoginLogo from '~/assets/icons/CaseWiseLoginLogo.svg'

const { getThemeToken } = useTheme()
const authFooter = getThemeToken('auth-footer')
const linearGradient = getThemeToken('linear-gradient')

const branding = useBrand()
const cobrandedExperience = computed(() => branding.value.brandingExperience === 'cobrand')

const cobrandedLogoUrl = computed(() => {
  return branding.value.svgLogoUrl || branding.value.pngLogoUrl
})

// Use the composable for dynamic head
const { applyHead } = useDynamicHead()
applyHead()
</script>
